import { Component, OnInit } from "@angular/core";
import { CoinRankingService } from "../../services/coin-ranking.service";

@Component({
  selector: "app-simple-trade",
  templateUrl: "./simple-trade.component.html",
  styleUrls: ["./simple-trade.component.scss"],
})
export class SimpleTradeComponent implements OnInit {
  tether;
  coinsArray = [];
  cryptoToken;
  coinName;
  selectedCoinAmount;
  fullName;
  topHundred

  toggled = false;
  toggleContainer = true;

  tetherImage = "https://logos-download.com/wp-content/uploads/2018/04/Tether_logo_coin.png";
  cryptoTokenImage;

  constructor(public coinRankingService: CoinRankingService) {}

  ngOnInit() {
    this.coinRankingService.getCoinRanking("top").subscribe((res: any) => {
      this.selectedCoinAmount = res.data[0].current_price_usd;
      this.cryptoTokenImage = res.data[0].logo
      this.coinName = res.data[0].baseCurrency;
      this.fullName = res.data[0].code;
      this.topHundred = res.data
      this.removeSelectedToken(this.topHundred)
    });
  }

  removeSelectedToken(coins) {
    for (let i = 0; i < coins.length; i++) {
      if (coins[i].code !== this.fullName && coins[i].code !== 'tether') {
        this.coinsArray.push(coins[i]);
      }
    }
  }

  onChangeTether(tetherValue) {
    this.priceCheckTether(tetherValue);
  }
  onChangeToken(tokenValue) {
    this.priceCheckToken(tokenValue);
  }

  selectedCoin(value, logo, name, fullName) {
    this.tether = "";
    this.cryptoToken = "";
    this.selectedCoinAmount = value;
    this.cryptoTokenImage = logo;
    this.coinName = name;
    this.toggled = false;
    this.fullName = fullName;
    this.coinsArray = [];
    this.removeSelectedToken(this.topHundred)
  }

  priceCheckTether(tetherValue) {
    let total = tetherValue / this.selectedCoinAmount;
    this.tether = tetherValue;
    this.cryptoToken = total;
  }

  priceCheckToken(tokenValue) {
    let total = tokenValue * this.selectedCoinAmount;
    this.tether = total;
    this.cryptoToken = tokenValue;
  }

  toggleList() {
    if (this.toggled === false) {
      this.toggled = true;
    } else {
      this.toggled = false;
    }
  }

  switchInput() {
    if (this.toggleContainer) {
      this.toggleContainer = false;
    } else {
      this.toggleContainer = true;
    }
  }
}
