import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'basic',
    loadChildren: () => import('../basic-trade/basic-trade.module').then(m => m.BasicTradeModule)
  },
  {
    path: 'coin-ranking',
    loadChildren: () => import('../coin-ranking-panel/coin-ranking-panel.module').then(m => m.CoinRankingPanelModule)
  },
  {
    path: '',
    redirectTo: '/coin-ranking',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule { }
