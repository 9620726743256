<div class="main"
  [ngClass]="{
    'dark': themeService.currentTheme === 'dark',
    'light': themeService.currentTheme === 'light'
  }"
>
  <div class="main-container">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</div>
