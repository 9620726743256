<div class="chart">
    <div class="chart-side-bar">
      <div></div>
        <div (click)="setChartType('line')">
            <svg [class.active]="chartType == 'line'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0" y="0" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                <polygon fill="#010101" points="45.8 47 3.8 47 3.8 1 5.8 1 5.8 45 45.8 45 "/>
                <rect x="0.8" y="7.5" fill="#010101" width="4" height="2"/>
                <rect x="0.8" y="15" fill="#010101" width="4" height="2"/>
                <rect x="0.8" y="22.5" fill="#010101" width="4" height="2"/>
                <rect x="0.8" y="30" fill="#010101" width="4" height="2"/>
                <rect x="0.8" y="37.5" fill="#010101" width="4" height="2"/>
                <path fill="#010101" d="M18.8 38.2C18.8 38.2 18.8 38.2 18.8 38.2c-3.7 0-6.1-10-7-14.3l-0.2-1 2-0.4 0.2 1c1.4 6.7 3.7 12.7 5 12.7l0 0c0 0 1.4-0.1 3-7.4 1.6-7.5 3.4-10.9 5.7-11 2.6-0.2 4.3 3.7 5.9 8 0.3 0.7 0.5 1.4 0.7 1.7 0.4 0.7 1 1.2 1.2 1.2 0 0 1.2-0.3 1.9-5.8 1.2-10 7.9-13.7 8.2-13.8l0.9-0.5 0.9 1.8 -0.9 0.5c-0.2 0.1-6.1 3.4-7.1 12.3 -0.6 4.8-1.7 7.2-3.5 7.6 -1.5 0.3-2.7-1-3.4-2.2 -0.2-0.4-0.4-1-0.8-1.9 -0.6-1.7-2.5-6.7-3.8-6.7 0 0 0 0 0 0 -0.2 0-1.9 0.4-3.9 9.5C22.4 35.4 20.9 38.2 18.8 38.2z"/>
              </svg>
        </div>
        <div (click)="setChartType('candlestick')">
            <svg [class.active]="chartType == 'candlestick'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0" y="0" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                <polygon fill="#010101" points="46 47 4 47 4 1 6 1 6 45 46 45 "/>
                <path fill="#010101" d="M21.3 25.5h-8.4v-12h8.4V25.5zM14.9 23.5h4.4v-8h-4.4V23.5z"/>
                <rect x="16.1" y="8.5" fill="#010101" width="2" height="6"/>
                <rect x="16.1" y="24.5" fill="#010101" width="2" height="6"/>
                <path fill="#010101" d="M34.1 33.5h-8.4v-12h8.4V33.5zM27.7 31.5h4.4v-8h-4.4V31.5z"/>
                <rect x="28.9" y="16.5" fill="#010101" width="2" height="6"/>
                <rect x="28.9" y="32.5" fill="#010101" width="2" height="6"/>
                <path fill="#010101" d="M47 27.5h-8.4v-12H47V27.5zM40.6 25.5H45v-8h-4.4V25.5z"/>
                <rect x="41.8" y="10.5" fill="#010101" width="2" height="6"/>
                <rect x="41.8" y="26.5" fill="#010101" width="2" height="6"/>
                <rect x="1" y="7.5" fill="#010101" width="4" height="2"/>
                <rect x="1" y="15" fill="#010101" width="4" height="2"/>
                <rect x="1" y="22.5" fill="#010101" width="4" height="2"/>
                <rect x="1" y="30" fill="#010101" width="4" height="2"/>
                <rect x="1" y="37.5" fill="#010101" width="4" height="2"/>
              </svg>

        </div>
        <div>
            <button class="panel-background" [class.active]="dataType == 'price'" (click)="setDataType('price')">Price</button>
        </div>
        <div>
            <button class="panel-background" [class.active]="dataType == 'volume'" (click)="setDataType('volume')">Volume</button>
        </div>
        <div>
          <button class="panel-background" [class.active]="dataType == 'something'" (click)="addData()">test</button>
      </div>
    </div>
    <div class="chart-content" [ngClass]="{ 'coin-details': currentPage==='coin-details'}">
        <div class="chart-top-bar" *ngIf="currentPage!=='coin-details'">
            <div class="item currency desktop-only">
                <div class="value">ETH</div>
                <div class="description">Ethereum</div>
            </div>
            <div class="item currency-price">
                <div class="value">{{currentPrice}}</div>
                <div class="description">USD</div>
            </div>

            <div class="daily-numbers">
                <div class="item desktop-only">
                    <div class="value">3.04%</div>
                    <div class="description">24h</div>
                </div>
                <div class="item mobile-only">
                    <div class="value">342.45</div>
                    <div class="description">24 Low</div>
                </div>
                <div class="item mobile-only">
                    <div class="value">395.23</div>
                    <div class="description">24 High</div>
                </div>
                <div class="item">
                    <div class="value">$13.94B</div>
                    <div class="description">24h Vol</div>
                </div>
                <div class="item">
                    <div class="value">$43.37B</div>
                    <div class="description">Market</div>
                </div>
            </div>
            <div class="market-numbers">
                <div class="item">
                    <div class="subheading">On Orders</div>
                    <div class="value">0</div>
                    <div class="description">$0.00</div>
                </div>
                <div class="item">
                    <div class="subheading">Frozen</div>
                    <div class="value">0</div>
                    <div class="description">$0.00</div>
                </div>
                <div class="item">
                    <div class="subheading">Total Available</div>
                    <div class="value">13.273629</div>
                    <div class="description">$5902.02</div>
                </div>
            </div>

        </div>

        <div #chartContainer class="chart-container" (window:resize)="onResize($event)">
          <div #tooltip class="tooltip"></div>
        </div>
    </div>

</div>
