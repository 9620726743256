import { Component, OnInit } from '@angular/core';
import { NewsFeedService } from '../../../services/news-feed.service';
import * as xml2js from 'xml2js'


@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  news;

  constructor(private newsFeedService: NewsFeedService) { }

  ngOnInit(): void {
    this.newsFeedService.getFeed().subscribe((data) => {
      const parseString = xml2js.parseString;
      parseString(data, (err, result) => {
        this.news = result.rss.channel[0].item;
        this.getImages();
      });
    });
}

  getImages(): void {
    for (let i = 0; i < this.news.length; i++) {
      const re = /<img[^>]+src="?([^"\s]+)"?[^>]*\/>/g;
      const results = re.exec(this.news[i].description[0]);
      if (results) {
        this.news[i].thumbnail = results[1];
      }
    }
  }

  onNavigate(item): void {
    window.open(item.link);
  }

}
