<div *ngIf="news" class="news-list-container">
  <div class="row" (click)="onNavigate(item)" *ngFor="let item of news">
    <img class="thumbnail" [src]="item.thumbnail" alt="">
    <div class="article-title">
      {{item.title}}
      <div class="article-date">
        {{item.pubDate[0].slice(4, 16)}}
      </div>
    </div>
  </div>
</div>
