import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as pluginLines from '../../../chart-plugins/line-plugin';
import '../../../lib/chartjs-chart-financial/chartjs-chart-financial';
import { BaseChartDirective, Color } from 'ng2-charts';
import * as moment from 'moment';

@Component({
  selector: 'app-chart-base',
  templateUrl: './chart-base.component.html',
  styleUrls: ['./chart-base.component.scss']
})
export class ChartBaseComponent implements OnInit, AfterViewInit {
  barCount = 60;
  initialDateStr = '01 Apr 2017 00:00 Z';
  @Output() hoverData = new EventEmitter<any>();
  @Input() colorFontDark = 'white';
  @Input() colorFontLight = 'white';
  @Input() colorGradientLight = 'rgba(105, 62, 216, {opacity})';
  @Input() colorGradientDark = 'rgba(105, 62, 216, {opacity})';
  @Input() colorGridDark = 'lightgrey';
  @Input() colorGridLight = '#693ED8';
  @Input() colorUpDark = '#39ff14';
  @Input() colorUpLight = '#4cb582';
  @Input() colorDownDark = '#ff073a';
  @Input() colorDownLight = '#C53C59';

  public chartData = [
    {
      // data: this.getRandomData(this.initialDateStr, this.barCount),
      data: this.generateData(),
      barThickness: 5,
      fill: true
    }
  ];
  public chartOptions: any;
  public chartColors: any[];
  public chartLegend = true;
  public chartType = 'line';
  public chartPlugins = [];
  title = 'Chart';
  isDark = false;
  currentDate = 'today';
  currentPrice = '0';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() {
  }

  ngOnInit(): void {
    this.chartOptions = {};
    this.chartLegend = true;
    this.chartPlugins = [pluginAnnotations, pluginLines];

  }

  ngAfterViewInit() {
    // console.log('afterViewInit')
    this.setChartType('line');
  }



  setChartType(chartType: string): void {
    let ctx = this.chart.ctx as any;
    let gradient = ctx.createLinearGradient(0, 0, 0, 400);

    gradient.addColorStop(0, this.colorGradientLight.replace('{opacity}', '0.5')); // 'rgba(255, 0,0, 0.5)');
    gradient.addColorStop(0.5, this.colorGradientLight.replace('{opacity}', '0.25'));
    gradient.addColorStop(1, this.colorGradientLight.replace('{opacity}', '0'));

    this.chartType = chartType;
    switch(this.chartType) {
      case 'line':
        this.chartLegend = true;
        this.chartPlugins = [pluginAnnotations, pluginLines];

        this.chartOptions = {
          maintainAspectRatio: false,
          responsive: true,
          elements: {
            point: {
              radius: 0,
              hoverRadius: 6,
              hoverBorderWidth: 2
            }
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              type:'time',
              distribution: 'series',
              ticks: {
                fontColor: this.colorFontLight,  // x axe labels (can be hexadecimal too)
                major: {
                  enabled: true,
                  fontStyle: 'bold'
                },
                source: 'data',
                autoSkip: true,
                autoSkipPadding: 75,
                maxRotation: 0,
                sampleSize: 100
              },
              gridLines: {
                display: false,
              }
            }],
            yAxes: [{
              stacked: true,
              position: 'right',
              ticks: {
                fontColor: this.colorFontLight,  // y axes numbers color (can be hexadecimal too)
              },
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: false,
                labelString: 'Price',
                fontColor: this.colorFontLight,  // y axe label color (can be hexadecimal too)
              }
            }]
          },
          tooltips: {
            caretPadding: 10,
            intersect: false,
            mode: 'index',
            backgroundColor:'rgba(107, 109, 135, 0.5)',
            custom: function(tooltip) {
              if (!tooltip) return;
              // disable displaying the color box;
              tooltip.displayColors = false;
            },
            callbacks: {
              label: function(tooltipItem, myData) {
                var label = [];
                label.push('$' + parseFloat(tooltipItem.value).toFixed(2));
                label.push(moment(tooltipItem.label).format('LL'))
                // console.log(tooltipItem);
                return label;
              },
              title: function(tooltipItem, data) {
                return;
              }
            }
          },
          hover: {
            mode: 'index',
            intersect: false,
            onHover: (evt, item) => { this.handleHover(evt, item) }
          },
          annotation: {
            annotations : [
              // {
              //   drawTime: "afterDraw",
              //   id: "hline",
              //   type: "line",
              //   mode: "horizontal",
              //   scaleID: "y-axis-0",
              //   value: this.chartData[0].data[this.chartData[0].data.length - 1].y,
              //   borderColor: this.colorGridDark,
              //   borderWidth: 1,
              //   borderDash: [1, 1],
              //   label: {
              //     backgroundColor: this.colorGridDark,
              //     content: this.chartData[0].data[this.chartData[0].data.length - 1].y,
              //     enabled: true,
              //     yAdjust: -20,
              //     position: 'right',
              //   }
              // }
            ]
          },
          plugins: {
            linePlugin: {
              color:'#6B6D87'
            }
          }
        };

        this.chartColors = [
          {
            backgroundColor: gradient,
            pointHoverBackgroundColor: this.colorGridLight,
            pointHoverBorderColor: this.colorFontDark,
            borderColor: this.colorGridLight
          },
        ];
        break;
      case 'candlestick':
        this.chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            mode: 'index',
            intersect: false,
            onHover: (evt, item) => { this.handleHover(evt, item) }
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              type:'time',
              distribution: 'series',
              ticks: {
                fontColor: this.colorFontLight,  // x axe labels (can be hexadecimal too)
                major: {
                  enabled: true,
                  fontStyle: 'bold'
                },
                source: 'data',
                autoSkip: true,
                autoSkipPadding: 75,
                maxRotation: 0,
                sampleSize: 100
              },
              gridLines: {
                color: 'transparent'
              }
            }],
            yAxes: [{
              stacked: true,
              position: 'right',
              ticks: {
                fontColor: this.colorFontLight,  // y axes numbers color (can be hexadecimal too)
              },
              gridLines: {
                color: 'transparent'
              },
              scaleLabel: {
                display: true,
                labelString: 'Price',
                fontColor: this.colorFontLight,  // y axe label color (can be hexadecimal too)
              }
            }]
          },
          tooltips: {
            caretPadding: 5,
            intersect: false,
            mode: 'index',
            backgroundColor:'rgba(107, 109, 135, 0.5)',
            custom: function(tooltip) {
              if (!tooltip) return;
              // disable displaying the color box;
              tooltip.displayColors = false;
            },
            callbacks: {
              label: function(tooltipItem, myData) {
                var label = [];
                label.push('Open: $' + parseFloat(this.chart.chart.tooltip._active[0]._model['candleOpen']).toFixed(2) + ' ' + 'Close: $' + parseFloat(this.chart.chart.tooltip._active[0]._model['candleClose']).toFixed(2));
                label.push(moment(tooltipItem.label).format('LL'))
                // console.log(tooltipItem);

                return label;
              }.bind(this),
              title: function(tooltipItem, data) {
                return;
              }
            }
          },
          plugins: {
            linePlugin: {
              color:'#6B6D87'
            }
          }
        };
        this.chartColors = [
          {
            // borderColor: {
            //   up: this.colorUpLight,
            //   down: this.colorDownLight,
            //   unchanged: this.colorUpLight
            // },
            // color: {
            //   up: this.colorUpLight,
            //   down: this.colorDownLight,
            //   unchanged: this.colorUpLight
            // },
            hoverBorderWidth: 0,
            hoverBorderColor: {},
          },
        ];

        this.chartLegend = true;
        this.chartPlugins = [];
        break;
    }

    this.setLightDarkColors();

    this.forceChartRefresh();
  }

  handleHover(evt, item) {
    if (item.length) {
      this.currentDate = moment(this.chartData[0].data[item[0]._index].t).format('LL');
      this.currentPrice = this.chartData[0].data[item[0]._index].y;
      this.hoverData.emit({currentDate: this.currentDate, currentPrice: this.currentPrice});
    }
  }

  toggleLightDark() {
    this.isDark = !this.isDark;

    this.setLightDarkColors();

    this.forceChartRefresh();
  }

  setLightDarkColors() {
    if(this.isDark) {
      this.chartOptions.scales.xAxes[0].ticks.fontColor = this.colorFontDark;
      this.chartOptions.scales.yAxes[0].ticks.fontColor = this.colorFontDark;
      // this.chartOptions.scales.xAxes[0].gridLines.color = this.colorGridDark;
      // this.chartOptions.scales.yAxes[0].gridLines.color = this.colorGridDark;
      this.chartOptions.scales.yAxes[0].scaleLabel.fontColor = this.colorFontDark;
      if(this.chartType === 'line') {
        this.chartColors[0]['borderColor'] = this.colorGridDark;
      } else if(this.chartType === 'candlestick') {
        this.chartColors[0]['borderColor'] = {
          up: this.colorUpLight,
          down: this.colorDownLight,
          unchanged: this.colorUpLight
        };
        this.chartColors[0]['color'] = {
          up: this.colorUpLight,
          down: this.colorDownLight,
          unchanged: this.colorUpLight
        };
      }
    } else {
      this.chartOptions.scales.xAxes[0].ticks.fontColor = this.colorFontLight;
      this.chartOptions.scales.yAxes[0].ticks.fontColor = this.colorFontLight;
      // this.chartOptions.scales.xAxes[0].gridLines.color = this.colorGridLight;
      // this.chartOptions.scales.yAxes[0].gridLines.color = this.colorGridLight;
      this.chartOptions.scales.yAxes[0].scaleLabel.fontColor = this.colorFontLight;
      if(this.chartType === 'line') {
        this.chartColors[0]['borderColor'] = this.colorGridLight;
      } else if(this.chartType === 'candlestick') {
        this.chartColors[0]['borderColor'] = {
          up: this.colorUpLight,
          down: this.colorDownLight,
          unchanged: this.colorUpLight
        };
        this.chartColors[0]['color'] = {
          up: this.colorUpLight,
          down: this.colorDownLight,
          unchanged: this.colorUpLight
        };
      }
    }
  }

  forceChartRefresh() {
    setTimeout(() => {
      (<any>this.chart).refresh();
    }, 10);
  }

  generateData() {
    const unit = 'day';

    function beforeNineThirty(date) {
      return date.hour() < 9 || (date.hour() === 9 && date.minute() < 30);
    }

    // Returns true if outside 9:30am-4pm on a weekday
    function outsideMarketHours(date) {
      if (date.isoWeekday() > 5) {
        return true;
      }

      return false;
    }

    function randomNumber(min, max) {
      return Math.random() * (max - min) + min;
    }

    function randomBar(date, lastClose) {
      const open = randomNumber(lastClose * 0.95, lastClose * 1.05).toFixed(2);
      const close = randomNumber(open * 0.95, open * 1.05).toFixed(2);
      const high = randomNumber(Math.max(open, close), Math.max(open, close) * 1.1).toFixed(2);
      const low = randomNumber(Math.min(open, close) * 0.9, Math.min(open, close)).toFixed(2);
      return {
        t: date,
        o: open,
        h: high,
        l: low,
        c: close,
        y: close
      };
    }

    let date = moment('Jan 01 1990', 'MMM DD YYYY');
    const now = moment();
    let data = [];
    const lessThanDay = false;
    for (; data.length < 100 && date.isBefore(now); date = date.clone().add(1, unit).startOf(unit)) {
      if (outsideMarketHours(date)) {
        if (!lessThanDay || !beforeNineThirty(date)) {
          date = date.clone().add(date.isoWeekday() >= 5 ? 8 - date.isoWeekday() : 1, 'day');
        }
        if (lessThanDay) {
          date = date.hour(9).minute(30).second(0);
        }
      }
      data.push(randomBar(date, data.length > 0 ? data[data.length - 1].y : 30));
    }

    return data;
  }
}
