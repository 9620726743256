import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type ThemeOptions = 'dark'|'light';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  currentTheme: ThemeOptions = 'dark';
  header = {
    title: new Subject<string>(),
    displaySearchCoins: new Subject<boolean>()
  }
  constructor() { }
}
