import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

// Services
import { ForTranslateService } from '../services/translate.service';
import { TranslateService } from '@ngx-translate/core';


@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(
      private translateService: ForTranslateService,
      public translate: TranslateService
    ) {}

  transform(key: string, args?: string[]): string {
    if (!key) {
      return '';
    }
    const processedKey = key.toLowerCase().replace(/ /g, '-');
    // if (this.translateService.translations[this.translateService.currentLanguage] &&
    //    this.translateService.translations[this.translateService.currentLanguage][processedKey]) {
    //     return this.processString(this.translateService.translations[this.translateService.currentLanguage][processedKey], args);
    // }
    const translation = this.translate.instant(key);
    return this.processString(translation, args);
  }

  processString(str: string, args: string[]) {
    if(!str || !args) {
      return str || '';
    }
    str = str.replace('(t)', '');
    args.forEach((fill, i) => {
      str = str.replace('{' + i + '}', fill);
    });

    return str;
  }
}
