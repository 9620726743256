import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  modeChange = false;
  type: 'basic' | 'advanced' = 'advanced';
  title = '';
  displaySearchCoins = false;

  constructor(
    public themeService: ThemeService,
    public router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.themeService.header.title.subscribe((newValue: string) => this.title = newValue);
    this.themeService.header.displaySearchCoins.subscribe((newValue: boolean) => this.displaySearchCoins = newValue);
  }

  checkStatus(event) {
    // console.log(event)
  }

  setTheme(event) {
    this.modeChange ? this.themeService.currentTheme = 'light' : this.themeService.currentTheme = 'dark';
  }

  navigate(url) {
    return this.router.navigate([url]);
  }

  navigateDashboard() {
    if (this.type === "basic") {
      return this.router.navigate(['/basic']);
    } else {
      return this.router.navigate(['/dashboard']);
    }
  }

  back() {
    this.location.back();
  }

}
