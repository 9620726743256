<div class="simple-trade-container">
  <div *ngIf="toggleContainer" class="trade-input">
    <div class="coin-container">
      <div class="coin-title">
        Convert Tether (USDT)
      </div>
      <div class="enter-amount input-color">
        <img [src]=tetherImage alt="">
        <input class="input-color text-color" placeholder="0" type="text" [ngModel]="tether" (ngModelChange)="onChangeTether($event)">
        <div class="token">USDT</div>
      </div>
      <div class="wallet"><i class="icon-wallet"></i><span>21213</span> USDT</div>
    </div>
    <!-- <i (click)="switchInput()" class="icon-star"></i> -->
    <div class="circle-background">
      <img class="arrow" (click)="switchInput()" src="../../../assets/images/arrow-both.png" alt="">
    </div>
    <!-- <img class="arrow" (click)="switchInput()" src="../../../assets/images/arrow-both.png" alt=""> -->
    <div class="coin-container">
      <div class="coin-title">
        To {{fullName}} ({{coinName}})
      </div>
      <div class="enter-amount input-color">
        <img [src]=cryptoTokenImage alt="">
        <input class="input-color text-color" placeholder="0" type="text" [ngModel]="cryptoToken" (ngModelChange)="onChangeToken($event)">
        <div (click)="toggleList()" class="token">
          <span *ngIf="toggled === false">{{coinName}}▼</span>
          <span *ngIf="toggled === true">{{coinName}}▲</span>
        </div>
      </div>



      <div *ngIf="toggled === true" class="search-container">
        <!-- <input (ngModelChange)="filterList($event)" [(ngModel)]="query" class="coin-search" type="text"
          placeholder="Search Tokens..."> -->
        <div class="coin-list-container">
          <div (click)="selectedCoin(coin.current_price_usd, coin.logo, coin.baseCurrency, coin.code)" class="coin" *ngFor="let coin of coinsArray">
            <app-simple-trade-row [coin]="coin"></app-simple-trade-row>
          </div>
        </div>
      </div>



      <div class="wallet">
        <i class="icon-wallet"></i><span>21213</span> USDT
      </div>
    </div>
  </div>

  <div *ngIf="!toggleContainer" class="trade-input">
    <div class="coin-container">
      <div class="coin-title">
        Convert {{fullName}} ({{coinName}})
      </div>
      <div class="enter-amount input-color">
        <img [src]=cryptoTokenImage alt="">
        <input class="input-color text-color" placeholder="0" type="text" [ngModel]="cryptoToken" (ngModelChange)="onChangeToken($event)">
        <div (click)="toggleList()" class="token">
          <span *ngIf="toggled === false">{{coinName}}▼</span>
          <span *ngIf="toggled === true">{{coinName}}▲</span>
        </div>
      </div>

      <div *ngIf="toggled === true" class="search-container">
        <!-- <input (ngModelChange)="filterList($event)" [(ngModel)]="query" class="coin-search" type="text"
          placeholder="Search Tokens..."> -->
        <div class="coin-list-container">
          <div (click)="selectedCoin(coin.current_price_usd, coin.logo, coin.baseCurrency, coin.code)" class="coin" *ngFor="let coin of coinsArray">
            <app-simple-trade-row [coin]="coin"></app-simple-trade-row>
          </div>
        </div>
      </div>


      <div class="wallet">
        <i class="icon-wallet"></i><span>21213</span> USDT
      </div>
    </div>
    <!-- <i (click)="switchInput()" class="icon-star"></i> -->
    <div class="circle-background">
      <img class="arrow" (click)="switchInput()" src="../../../assets/images/arrow-both.png" alt="">
    </div>
    <!-- <img class="arrow" (click)="switchInput()" src="../../../assets/images/arrow-both.png" alt=""> -->
    <div class="coin-container">
      <div class="coin-title">
        To Tether (USDT)
      </div>
      <div class="enter-amount input-color">
        <img [src]=tetherImage alt="">
        <input class="input-color text-color" placeholder="0" type="text" [ngModel]="tether" (ngModelChange)="onChangeTether($event)">
        <div class="token">USDT</div>
      </div>
      <div class="wallet"><i class="icon-wallet"></i><span>21213</span> USDT</div>
    </div>
  </div>

  <button class="convert-button">Convert</button>
</div>
