import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appShakeOnClick]'
})
export class ShakeOnClickDirective {

  constructor(private el: ElementRef) {}
  @Input('appShakeOnClick') isAppShakeOnClick: boolean;

  removeClassTimeout: any;

  @HostListener('click', [])
    onClick() {
      if (!this.isAppShakeOnClick) {
        return;
      }
      if (this.removeClassTimeout) {
        this.el.nativeElement.classList.remove('animation-shake');
        clearTimeout(this.removeClassTimeout);
      }

      if (!this.el.nativeElement.classList.contains('animation-shake')) {
        this.el.nativeElement.classList.add('animation-shake');
        this.removeClassTimeout = setTimeout(() => {
          this.el.nativeElement.classList.remove('animation-shake');
        }, 3000);
      }
    }
}
