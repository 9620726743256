<div class="coin-row-container">
  <div class="crypto-name">
    <i [ngClass]="favorite ? 'icon-full-star' : 'icon-star'" (click)="toggleFavorite()"></i>
    <img [src]="image" alt="">
    <div class="name-row">
      <div class="acronyms">{{cryptoCurrency.coin}}</div>
      <div class="full-name">{{cryptoCurrency.coin}}</div>
    </div>
  </div>
  <div class="crypto-numbers">
    <div class="prices">${{cryptoCurrency.USD.PRICE}}</div>
    <div class="volume-container" >
      <div *ngIf="percentage > 0" class="green arrow">▲</div>
      <div *ngIf="0 > percentage" class="red arrow">▼</div>
      <div [ngClass]="percentage > 0 ? 'green' : 'red'">{{percentage}}%</div>
    </div>
  </div>
</div>
