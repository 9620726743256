import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-trade-row',
  templateUrl: './simple-trade-row.component.html',
  styleUrls: ['./simple-trade-row.component.scss']
})
export class SimpleTradeRowComponent implements OnInit {

  @Input() coin: any;

  constructor() { }

  ngOnInit() {
  }

}
