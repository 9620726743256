import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-coin-row',
  templateUrl: './coin-row.component.html',
  styleUrls: ['./coin-row.component.scss']
})
export class CoinRowComponent implements OnInit {

  @Input() cryptoCurrency: any;
  image: string;
  percentage;
  favorite = false;

  constructor() { }

  ngOnInit() {
    this.image = `https://cryptocompare.com${this.cryptoCurrency.USD.IMAGEURL}`;
    this.percentage = this.cryptoCurrency.USD.CHANGEPCT24HOUR.toString().substring(0, 4);
  }

  toggleFavorite() {
    if (!this.favorite) {
      this.favorite = true;
    } else {
      this.favorite = false;
    }
  }

}
