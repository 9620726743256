import { Injectable } from '@angular/core';

// Constants
import { EnglishTranslations } from '../constants/us';
import { ChineseTranslations } from '../constants/cn';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class ForTranslateService {

  public translations = {
    'us': EnglishTranslations,
    'cn': ChineseTranslations,
  };

  _languages = [
    {
      language: 'us',
      locale: 'us',
      text: 'English',
      image: './assets/images/flags/united-states-of-america-flag-xs.png'
    },
    {
      language: 'cn',
      locale: 'zh',
      text: '中文',
      image: './assets/images/flags/china-flag-xs.png'
    },
    {
      language: 'tw',
      locale: 'zh_TW',
      text: '繁體中文',
      image: './assets/images/flags/hongkong-flag-xs.png'
    },
    {
      language: 'vi',
      locale: 'vi_VN',
      text: 'Tiếng Việt',
      image: './assets/images/flags/vietnam-flag-xs.png'
    },
    {
      language: 'ja',
      locale: 'ja_JP',
      text: '日本語',
      image: './assets/images/flags/japan-flag-xs.png'
    },
    {
      language: 'kr',
      locale: 'ko_KR',
      text: '한국어',
      image: './assets/images/flags/south-korea-flag-xs.png'
    },
    {
      language: 'ru',
      locale: 'ru',
      text: 'русский язык',
      image: './assets/images/flags/russia-flag-xs.png'
    },    {
      language: 'th',
      locale: 'th',
      text: 'ไทย',
      image: './assets/images/flags/thailand-flag-xs.png'
    },
    // {
    //   language: 'es',
    //   text: 'Español',
    //   image: './assets/images/flags/mexico-flag-xs.png'
    // },
    // {
    //   language: 'de',
    //   text: 'Deutsch',
    //   image: './assets/images/flags/germany-flag-xs.png'
    // },
    // {
    //   language: 'fr',
    //   text: 'français',
    //   image: './assets/images/flags/france-flag-xs.png'
    // }
  ];

  public languageFlagMap = {
    'us': './assets/images/flags/united-states-of-america-flag-xs.png',
    'cn': './assets/images/flags/china-flag-xs.png',
    'gb': './assets/images/flags/united-kingdom-flag-xs.png',
    'cl': './assets/images/flags/chile-flag-xs.png',
    'zh': './assets/images/flags/china-flag-xs.png',
    'hk': './assets/images/flags/hongkong-flag-xs.png',
    'tw': './assets/images/flags/taiwan.svg',
    'kh': './assets/images/flags/cambodia-flag-xs.png',
    'vi': './assets/images/flags/vietnam-flag-xs.png',
    'vn': './assets/images/flags/vietnam-flag-xs.png',
    'vt': './assets/images/flags/vietnam-flag-xs.png',
    'uk': './assets/images/flags/united-kingdom-flag-xs.png',
    'ja': './assets/images/flags/japan-flag-xs.png',
    'jp': './assets/images/flags/japan-flag-xs.png',
    'kz': './assets/images/flags/kazakhstan-flag-xs.png',
    'kr': './assets/images/flags/south-korea-flag-xs.png',
    'ko': './assets/images/flags/south-korea-flag-xs.png',
    'my': './assets/images/flags/malaysia-flag-xs.png',
    'mm': './assets/images/flags/myanmar-flag-xs.png',
    'in': './assets/images/flags/india-flag-xs.png',
    'sg': './assets/images/flags/singapore-flag-xs.png',
    'hu': './assets/images/flags/hungary-flag-xs.png',
    'mo': './assets/images/flags/macau-flag-xs.png',
    'ru': './assets/images/flags/russia-flag-xs.png',
    'th': './assets/images/flags/thailand-flag-xs.png',
    'au': './assets/images/flags/australia-flag-xs.png',
    'tr': './assets/images/flags/turkey-flag-xs.png',
  };

  public currentLanguageObj;
  public _currentLanguage = this.getStoredLanguage();
  public _onLanguageChange = new Subject<string>();

  constructor(
    public translate: TranslateService
  ) {}

  get languages() {
        return JSON.parse(JSON.stringify(this._languages));
  }

    get currentLanguage() {
        return this._currentLanguage;
    }

    get onLanguageChange() {
        return this._onLanguageChange.asObservable();
    }

    set setCurrentLanguage(language: string) {
        if (!language) {
            language = localStorage.getItem('lang');
            if(language){
                this.translate.setDefaultLang(language);
                this._onLanguageChange.next(language);
                return;
            }else{
                this.translate.setDefaultLang('en');
                this._onLanguageChange.next(language);
                return;
            }
        }
        this.translate.setDefaultLang(language);

        if (!language || !this._languages.find(lang => lang.language === language)) {
            return;
        }

        this.currentLanguageObj = this._languages.find(lang => lang.language === language);
        localStorage.setItem('lang', language);
        this._currentLanguage = language;
        this._onLanguageChange.next(language);
    }

    getStoredLanguage() {
      this.translate.addLangs(['zh', 'en', 'zh_TW', 'kr', 'vn', 'jp', 'ru']);
      const lang = localStorage.getItem('lang');
      this.translate.setDefaultLang(lang || 'en');

      if (lang && this._languages.find(language => language.language === lang)) {
        this.currentLanguageObj = this._languages.find(language => language.language === lang);
        return lang;
      }
      this.currentLanguageObj = this._languages.find(language => language.language === 'us');
      return 'us';
    }

    getFlagImage(country: string) {
      if (country) {
        return this.languageFlagMap[country.toLowerCase()];
      }
    }
}
