import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppCommonModule } from './common/common.module';
import { RoutingModule } from './routing/routing.module';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './services/auth.service';
import { CoinRankingService } from './services/coin-ranking.service';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { NewsFeedService } from './services/news-feed.service';
import { ForTranslateService } from './services/translate.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '', '');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppCommonModule,
    RoutingModule,
    HttpClientModule,
    ChartsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    ThemeService,
    CoinRankingService,
    TranslateService,
    ForTranslateService,
    NewsFeedService,
    AuthService,
    TranslateStore,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
