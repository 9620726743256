import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CoinRankingService {

  url = 'https://min-api.cryptocompare.com/data/pricemultifull';
  market = '&tsyms=USD&e=Coinbase';
  coins = '?fsyms=BTC,ETH,XRP,USDT,BCH,LTC,LINK,OMG,BNB,ADA,DOT,XLM,BSV,EOS,TRX,XEM,UNI,MKR,YFI';
  key = '&api_key=32bcf25de42cdc2dfce6176731239556beb85906a5566e11ed8eb43e9b827aca';

  apiUrl = 'http://8.129.118.5';

  constructor(
    public http: HttpClient,
  ) { }

  getCrypto(): Observable<any> {
    const url = `${this.url}${this.coins}${this.market}${this.key}`;
    return this.http.get(url);
  }

  getCoinRanking(type: string) {
    const url = `${this.apiUrl}/core/api/coinRank/${type}`;
    return this.http.get(url);
  }

}
