import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsFeedService {
  private url = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fbtcpro.com%2Ffeed%2F';

  constructor(private http: HttpClient) {}

  getFeed(): Observable<any> {
    const requestOptions: object = {
      observe: 'body',
      responseType: 'text',
    };
    return this.http.get<any>('https://btcpro.com/feed/', requestOptions);
  }
}
