<div class="chart-base-container">
      <canvas baseChart 
            [datasets]="chartData" 
            [options]="chartOptions"
            [colors]="chartColors" 
            [legend]="chartPlugins" 
            [chartType]="chartType" 
            [plugins]="chartPlugins"
            >
        </canvas>
  </div>