<div class="header-container">
  <div class="row desktop-only">
    <div class="logo">
    </div>
    <div class="navigation">
      <button class="nav-button version">
        <div [ngClass]="{selected: type === 'basic'}" (click)="type = 'basic'" class="basic" (click)="navigate('/basic')">
          BASIC
        </div>
        <div [ngClass]="{selected: type === 'advanced'}" (click)="type = 'advanced'" class="advanced" (click)="navigate('/dashboard')">
          ADVANCED
        </div>
      </button>
      <button class="switch-container nav-button">
        <label class="switch mb-0" (click)="$event.stopPropagation()">
          <input id="message" [(ngModel)]="modeChange" type="checkbox" (ngModelChange)="setTheme($event)">
          <span class="slider round"></span>
        </label>
      </button>
      <button class="nav-button" (click)="navigateDashboard()">Dashboard</button>
      <button class="nav-button">Wallet</button>
      <button class="nav-button" (click)="navigate('/coin-ranking')">Coin Ranking</button>
      <button class="user nav-button">
        <img src="https://vignette.wikia.nocookie.net/yugioh/images/1/12/Dark_Magician_Girl_%28character%29.jpg/revision/latest?cb=20120604021929" alt="">
        <div class="username"> SatoshiNakamoto123</div>
      </button>
    </div>
  </div>
  <div *ngIf="!displaySearchCoins" class="row standard-header mobile-only">
    <div>
      <i (click)="back()" class="icon-chevronleft"></i>
    </div>
    <div>
      {{title}}
    </div>
    <div>
      <i class="icon-star"></i>
    </div>
  </div>
  <div *ngIf="displaySearchCoins" class="row search-coins text-color mobile-only">
    <label class="search-input">
      <i class="icon-searchsimple"></i>
      <input class="input-color" placeholder="Search Coins" type="text">
    </label>
    <i class="menu"></i>
  </div>
</div>
