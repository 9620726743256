import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartBaseComponent } from './chart-base/chart-base.component';
import { ChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { CoinRowComponent } from './coin-row/coin-row.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ShortNumberPipe } from '../pipes/short-number.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { ShakeOnClickDirective } from '../directives/shake-on-click.directive';
import { GenericLoaderComponent } from '../animations/generic-loader/generic-loader.component';
import { SimpleTradeComponent } from './simple-trade/simple-trade.component';
import { SimpleTradeRowComponent } from './simple-trade-row/simple-trade-row.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ChartComponent } from './chart/chart.component';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsComponent } from './news/news.component';


@NgModule({
  declarations: [
    ChartBaseComponent,
    HeaderComponent,
    CoinRowComponent,
    ShortNumberPipe,
    TranslatePipe,
    ShakeOnClickDirective,
    GenericLoaderComponent,
    SimpleTradeComponent,
    SimpleTradeRowComponent,
    PromotionsComponent,
    ChartComponent,
    NewsListComponent,
    NewsComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatTabsModule,
  ],
  exports: [
    HeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    ChartBaseComponent,
    CoinRowComponent,
    MatTabsModule,
    ShortNumberPipe,
    TranslatePipe,
    ShakeOnClickDirective,
    GenericLoaderComponent,
    SimpleTradeComponent,
    SimpleTradeRowComponent,
    PromotionsComponent,
    ChartComponent,
    NewsListComponent,
    NewsComponent,
  ],
})
export class AppCommonModule {}
